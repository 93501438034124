import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AccountStatusPage = () => {
    const navigate = useNavigate();

    const {
        creatorName,
        verified,
        verificationDate,
        applications,
        status,
        email
    } = useSelector((state) => state.user);

    const isLoading = status === 'loading';

    const getStatusText = () => {
        if (isLoading) return null;
        if (verified) return "Creator";
        if (!verified && applications) return "Pending";
        return "Visitor";
    };

    const getStatusColor = () => {
        if (isLoading) return '#000';
        if (verified) return '#28a745'; // Success green
        if (!verified && applications) return '#ffc107'; // Warning yellow
        return '#dc3545'; // Danger red
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column', // Stack card + buttons
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                margin: 0,
                fontFamily: 'Arial, sans-serif',
            }}
            className="container-fluid"
        >
            {/* Card Container */}
            <div
                className="card shadow-sm"
                style={{
                    textAlign: 'center',
                    background: 'rgba(248, 249, 250, 0.95)',
                    padding: '20px',
                    borderRadius: '15px',
                    width: '90%',
                    maxWidth: '400px',
                }}
            >
                <h2 style={{ fontSize: '1.8rem', marginBottom: '10px' }}>Account Status</h2>

                {/* Hourglass Animation */}
                <div
                    style={{
                        animation: 'flip 10s ease-in-out infinite',
                        position: 'relative',
                        margin: '0 auto 20px',
                        height: '20vmin',
                        width: '12vmin',
                    }}
                >
                    {/* Top Part */}
                    <div
                        style={{
                            backgroundColor: '#def',
                            height: '10vmin',
                            borderRadius: '0 0 50% 50%',
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                animation: 'top 10s linear infinite',
                                backgroundColor: '#000000',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '10vmin',
                                width: '10vmin',
                                transform: 'translateY(50%)',
                            }}
                        />
                    </div>

                    {/* Bottom Part */}
                    <div
                        style={{
                            backgroundColor: '#def',
                            height: '10vmin',
                            borderRadius: '50% 50% 0 0',
                            overflow: 'hidden',
                            position: 'relative',
                            marginTop: '-2px',
                        }}
                    >
                        <div
                            style={{
                                animation: 'bottom 10s linear infinite',
                                backgroundColor: '#000000',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '10vmin',
                                width: '10vmin',
                                transform: 'translateY(100%)',
                            }}
                        />
                    </div>
                </div>

                {/* Account Status Info */}
                <h4 style={{ color: '#6c757d', marginBottom: '0px' }}>
                    {creatorName}
                </h4>
                <h4 style={{ fontSize:'12px', marginBottom: '20px' }}>
                    {email}
                </h4>
                <h4
                    style={{
                        fontSize: '1.4rem',
                        fontWeight: 'bold',
                        color: getStatusColor(),
                        marginBottom: '10px',
                    }}
                >
                    {isLoading ? 'Loading...' : getStatusText()}
                </h4>
                <h4 style={{ color: '#6c757d', fontSize: "12px" }}>
                    {isLoading
                        ? 'Please wait...'
                        : verified
                        ? `Account verified on: ${verificationDate}`
                        : 'Your account is not verified yet'}
                </h4>
            </div>

            {/* Buttons for Each Status */}
            {!isLoading && !verified && !applications && (
                <button
                    type="button"
                    className="btn btn-primary btn-lg shadow-sm mt-3"
                    onClick={() => navigate('/apply')}
                >
                    Apply
                </button>
            )}

            {!isLoading && !verified && applications && (
                <button
                    type="button"
                    className="btn btn-primary btn-lg shadow-sm mt-3"
                    onClick={() => navigate('/apply')}
                >
                    New Application
                </button>
            )}

            {!isLoading && verified && (
                <button
                    type="button"
                    className="btn btn-primary btn-lg shadow-sm mt-3"
                    onClick={() => navigate('/')}
                >
                    Home
                </button>
            )}

            {/* Hourglass Animations */}
            <style>
                {`
                @keyframes flip {
                    0%, 45% {
                        transform: rotate(0);
                    }
                    50%, 95% {
                        transform: rotate(180deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
                @keyframes top {
                    0% {
                        transform: translateY(50%);
                    }
                    50% {
                        transform: translateY(100%);
                    }
                    51% {
                        transform: translateY(-100%);
                    }
                    100% {
                        transform: translateY(-50%);
                    }
                }
                @keyframes bottom {
                    0% {
                        transform: translateY(100%);
                    }
                    50% {
                        transform: translateY(50%);
                    }
                    51% {
                        transform: translateY(-50%);
                    }
                    100% {
                        transform: translateY(-100%);
                    }
                }
            `}
            </style>
        </div>
    );
};

export default AccountStatusPage;