import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <footer className="text-center text-lg-start bg-dark text-light">
            <section className="border-bottom" style={{ borderColor: '#444' }}>
                <div className="container text-center mt-5">
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-lg-3 d-flex flex-column align-items-center mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4 text-center">
                                <i className="fas fa-gem me-3"></i>Cay Collective AB
                            </h6>
                            <p className="text-center">The Cay Creator Site is a part of Cay Collective.</p>
                        </div>
                        <div className="col-md-4 col-lg-3 d-flex flex-column align-items-center mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4 text-center">Useful links</h6>
                            <p>
                                <Link to="/terms-of-service" className="text-reset text-light">
                                    Terms of Service
                                </Link>
                            </p>
                            <p>
                                <Link to="/privacy-policy" className="text-reset text-light">
                                    Privacy Policy
                                </Link>
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-3 d-flex flex-column align-items-center mx-auto mb-md-0 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4 text-center">Contact</h6>
                            <p className="text-center">
                                <i className="fas fa-home me-3"></i> Stockholm, SE 11134, SV
                            </p>
                            <p className="text-center">
                                <i className="fas fa-envelope me-3"></i> hello@caycollective.com
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-center p-4" style={{ backgroundColor: '#212529', color: 'white' }}>
                © 2024 Copyright: Cay Collective AB
            </div>
        </footer>
    );
};