// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll, list } from "firebase/storage";
import { addDoc, collection, getDoc, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBd1rgfHoq_rSzpf26EfXDjHhkDFcBG03g",
  authDomain: "caycreator.com",
  projectId: "caydb-ac2a7",
  storageBucket: "caydb-ac2a7.appspot.com",
  messagingSenderId: "98714203691",
  appId: "1:98714203691:web:fcd778fa544dde4f341c6e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);


export { ref, uploadBytes, getDownloadURL, deleteObject, listAll, list };
export { addDoc, collection, getDoc, doc, setDoc, updateDoc, deleteDoc };