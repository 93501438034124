import React, { useState } from 'react';
import { db } from '../config/firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

// Icons from react-icons
import { BsX } from 'react-icons/bs';

export const FeedbackWidget = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Toggle the card above the button
  const toggleWidget = () => {
    if (!isSubmitting) {
      setIsOpen((prev) => !prev);
    }
  };

  // Send feedback to Firestore
  const handleSendFeedback = async () => {
    if (!feedbackText.trim() || isSubmitting) return; // Ignore empty messages or if already submitting

    setIsSubmitting(true);
    try {
      await addDoc(collection(db, 'feedback'), {
        userId: user.uid,
        message: feedbackText.trim(),
        timestamp: serverTimestamp(),
        pageUrl: window.location.href,
      });

      // Reset form and close widget
      setFeedbackText('');
      setIsOpen(false);
    } catch (error) {
      console.error('Error sending feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 9999 }}>
      {/* The feedback card appears above the button when isOpen === true */}
      {isOpen && (
        <div
          className="card shadow"
          style={{
            width: '18rem',
            marginBottom: '62px', // Push card above the button
          }}
        >
          <div className="card-body d-flex flex-column">
            {/* Header with title + close icon */}
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5 className="card-title mb-0 d-flex align-items-center">
                Feedback
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-dark"
                onClick={toggleWidget}
                disabled={isSubmitting}
              >
                <BsX />
              </button>
            </div>

            {/* Text area for feedback */}
            <div className="mb-3">
              <textarea
                className="form-control"
                rows="5"
                placeholder="Give us feedback..."
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value)}
                style={{
                  resize: 'none', // Disable resizing
                  overflowY: 'auto', // Add vertical scroll if needed
                }}
                disabled={isSubmitting}
              />
            </div>

            {/* Send button */}
            <div className="d-flex justify-content-end">
              {isSubmitting ? (
                <button
                  className="btn btn-primary d-flex align-items-center"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Sending...
                </button>
              ) : (
                <button
                  className="btn btn-primary d-flex align-items-center"
                  onClick={handleSendFeedback}
                >
                  Send
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {/* The always-visible toggle button at bottom-right */}
      <button
        onClick={toggleWidget}
        className="btn btn-dark d-flex align-items-center rounded-pill"
        style={{
          padding: '0.6rem 1rem',
          position: 'absolute', // Ensure fixed positioning
          bottom: 0,
          right: 0,
        }}
      >
        FEEDBACK
      </button>
    </div>
  );
};