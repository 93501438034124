import React from 'react';
import { Link } from 'react-router-dom';
import underDevImage from '../media/underdev.jpg'; // Correctly import the image

export const UnderDevelopmentPage = () => {
  return (
    <div
      className="container-fluid d-flex flex-column justify-content-center align-items-center text-center"
      style={{
        height: '100vh',
        color: '#333333', // Dark text for contrast
        padding: '20px',
      }}
    >
      <div className="row justify-content-center align-items-center">
        <div className="col-md-6 mb-4">
          {/* Image */}
          <img
            src={underDevImage} // Use the imported image
            alt="Under Development"
            className="img-fluid"
            style={{
              maxWidth: '100%',
              borderRadius: '10px', // Optional rounded corners
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            }}
          />
        </div>
        <div className="col-md-6">
          {/* Header */}
          <h1 className="display-4 mb-4" style={{ fontWeight: 'bold' }}>
            This Page is Under Development
          </h1>
          {/* Description */}
          <p className="mb-4" style={{ fontSize: '18px', lineHeight: '1.6' }}>
            We're working hard to bring you something amazing! Stay tuned for updates.
          </p>
          {/* Back to Home Button */}
          <Link
            to="/"
            className="btn btn-primary"
            style={{
              backgroundColor: '#007bff',
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '5px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for button
            }}
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};