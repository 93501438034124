// src/components/Navbar.js

import { Link } from "react-router-dom";
import { auth } from "../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import logo from '../media/cay_logo.png';
import {
  BsBook,
  BsPlusSquare,
  BsPencilSquare,
  BsCardImage,
  BsBoxSeam,
  BsCashCoin,
  BsArrowDownUp,
  BsUniversalAccess,
} from "react-icons/bs";
import { FaCog } from 'react-icons/fa';

export const Navbar = () => {
    const [user] = useAuthState(auth);
    const signUserOut = async () => {
        await signOut(auth);
    };

    // Function to collapse the navbar
    const handleCollapse = () => {
        const navbarToggle = document.querySelector('.navbar-toggler');
        if (!navbarToggle) return; // Exit if navbar-toggler is not found
        const isExpanded = navbarToggle.getAttribute('aria-expanded') === 'true';
        if (isExpanded) {
            navbarToggle.click();
        }
    };

    return (
        <>
            {/* Inline CSS to remove hover effects on small screen navbar links and style the navbar-toggler */}
            <style>
                {`
                    /* Custom styles for navbar-toggler */
                    .navbar-toggler {
                        border: none; /* Remove border */
                        transition: transform 0.3s ease; /* Smooth rotation */
                        /* Optional: Add a subtle box-shadow for better visibility */
                        box-shadow: none;
                    }

                    .navbar-toggler:focus {
                        box-shadow: none; /* Remove focus outline */
                    }

                    /* Rotate the toggler when active */
                    .navbar-toggler[aria-expanded="true"] {
                        transform: rotate(90deg); /* Rotate 90 degrees */
                    }

                    /* Optional: Adjust the toggler-icon size */
                    .navbar-toggler-icon {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                `}
            </style>

            {/* Navbar for large screens */}
            <nav className="navbar navbar-expand-lg navbar-light shadow-sm sticky-top d-none d-sm-flex" style={{ backgroundColor: 'rgba(255, 255, 255)', paddingBottom: '10px', paddingTop: '10px' }}>
                <div className="container-fluid d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
  {user && (
    <div className="d-flex align-items-center">
      <img
        src={user.photoURL || ""}
        width="30"
        height="30"
        alt="User Profile"
        className="rounded-circle me-2"
        title={user.email}           // Tooltip on hover for the image
      />
      <span
        className="fw-semibold"
        title={user.email}           // Tooltip on hover for the name
      >
        {user.displayName}
      </span>
    </div>
  )}
</div>

                    <Link to="/" className="navbar-brand mx-auto position-absolute start-50 translate-middle-x">
                        <img src={logo} alt="Company Logo" height="35" />
                    </Link>

                    {user && (
                        <div className="d-flex ms-auto">
                            <div className="dropdown">
                                <button className="btn p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FaCog size={24} />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                    <li>
                                        <Link className="dropdown-item" to="/account-status">Account</Link>
                                    </li>
                                    <li>
                                        <button onClick={signUserOut} className="dropdown-item">Log Out</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </nav>

            {/* Navbar for small screens */}
            <nav className="navbar navbar-expand-lg navbar-light shadow-sm sticky-top d-block d-sm-none" style={{ backgroundColor: 'rgba(255, 255, 255)', paddingBottom: '10px', paddingTop: '10px' }}>
                <div className="container-fluid d-flex justify-content-between align-items-center">
                    <Link to="/" className="navbar-brand">
                        <img src={logo} alt="Company Logo" height="30" />
                    </Link>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarToggleContent"
                        aria-controls="navbarToggleContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarToggleContent">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item pt-4">
                                <Link to="/add-products" className="nav-link text-dark text-decoration-none" onClick={handleCollapse}>
                                    <BsPlusSquare className="me-2" /> Add Products
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/orders" className="nav-link text-dark text-decoration-none" onClick={handleCollapse}>
                                    <BsBoxSeam className="me-2" /> Orders
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/content" className="nav-link text-dark text-decoration-none" onClick={handleCollapse}>
                                    <BsCardImage className="me-2" /> Content
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/allProductsPage" className="nav-link text-dark text-decoration-none" onClick={handleCollapse}>
                                    <BsPencilSquare className="me-2" /> Edit Products
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/payments" className="nav-link text-dark text-decoration-none" onClick={handleCollapse}>
                                    <BsCashCoin className="me-2" /> Payments
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/creator-shop" className="nav-link text-dark text-decoration-none" onClick={handleCollapse}>
                                    <BsBook className="me-2" /> My Shop
                                </Link>
                            </li>
                            <li className="nav-item mb-4">
                                <Link to="/returns" className="nav-link text-dark text-decoration-none" onClick={handleCollapse}>
                                    <BsArrowDownUp className="me-2" /> Returns
                                </Link>
                            </li>
                            <li className="nav-item mb-2">
                                {user && (
                                    <button onClick={signUserOut} className="btn btn-secondary btn-sm mt-2">
                                        Log Out
                                    </button>
                                )}
                            </li>
                            <li className="nav-item mb-0">
                                <Link to="/account-status" className="nav-link text-dark text-decoration-none" onClick={handleCollapse}>
                                    <BsUniversalAccess className="me-2" /> Account
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );

};

export default Navbar;