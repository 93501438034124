import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateProduct } from '../../redux/slices/productsSlice'; // Import the updateProduct action
import { v4 as uuidv4 } from 'uuid'; // To generate unique keys for options

export const OptionsManager = () => {
    const { id } = useParams(); // Get product ID from the URL
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products); // Get all products
    const product = products?.find((product) => product.id === id); // Find the product by ID
    const options = product?.options || []; // Directly use options from Redux, no local state

    const [tempValues, setTempValues] = useState({}); // Temporary input values for each option

    // Function to add a new option (e.g., Size, Color) with a maximum of 3 options
    const handleAddOption = () => {
        if (options.length < 3) {
            const newOptions = [...options, { id: uuidv4(), name: '', values: [] }];
            dispatch(updateProduct({ id: product.id, field: 'options', value: newOptions }));
        }
    };

    // Function to update an option name or values
    const handleOptionChange = (optionIndex, field, value) => {
        const updatedOptions = options.map((option, index) =>
            index === optionIndex ? { ...option, [field]: value.charAt(0).toUpperCase() + value.slice(1) } : option
        );
        dispatch(updateProduct({ id: product.id, field: 'options', value: updatedOptions }));
    };

    // Function to handle temporary input for option values before pressing enter or comma
    const handleTempValueChange = (optionIndex, value) => {
        setTempValues({ ...tempValues, [optionIndex]: value });
    };

    // Function to update option values
    const handleOptionValuesChange = (optionIndex, event) => {
        const value = event.target.value.trim();
        const key = event.key;

        if (key === ',' || key === 'Enter') {
            event.preventDefault(); // Prevent comma/Enter from being typed in the input field
            if (value) {
                const updatedOptions = options.map((option, index) =>
                    index === optionIndex
                        ? { ...option, values: [...option.values, value.charAt(0).toUpperCase() + value.slice(1)] }
                        : option
                );
                dispatch(updateProduct({ id: product.id, field: 'options', value: updatedOptions }));
                setTempValues({ ...tempValues, [optionIndex]: '' }); // Reset temporary input value
            }
        }
    };

    // Function to delete a specific value from an option's values
    const handleDeleteValue = (optionIndex, valueIndex) => {
        const updatedOptions = options.map((option, index) =>
            index === optionIndex
                ? { ...option, values: option.values.filter((_, i) => i !== valueIndex) } // Remove the value
                : option
        );
        dispatch(updateProduct({ id: product.id, field: 'options', value: updatedOptions }));
    };

    // Function to delete an option
    const handleDeleteOption = (optionIndex) => {
        const updatedOptions = options.filter((_, index) => index !== optionIndex);
        dispatch(updateProduct({ id: product.id, field: 'options', value: updatedOptions }));
    };

    if (!product) return <div>Loading product data...</div>;

    return (
        <div className="container p-4 mb-4 rounded" style={{ backgroundColor: '#f0f0f0' }}>
            <div className="mb-0">
                <button
                    className="btn btn-primary mb-3"
                    onClick={handleAddOption}
                    disabled={options.length >= 3} // Disable the button when 3 options are added
                >
                    Add Option
                </button>

                <div className="row g-0">
                    {options.map((option, optionIndex) => (
                        <div key={option.id} className="col-sm-8 col-md-6 col-lg-4 p-1">
                            <div className="card shadow-sm position-relative">
                                {/* Delete Option Button */}
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm position-absolute"
                                    style={{
                                        top: '5px',
                                        right: '5px',
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '20%',
                                        padding: '0',
                                        fontSize: '0.7rem',
                                        zIndex: 1,
                                    }}
                                    onClick={() => handleDeleteOption(optionIndex)}
                                >
                                    &times;
                                </button>

                                <div className="card-body">
                                    <input
                                        type="text"
                                        className="form-control mb-1"
                                        placeholder='"Add option"'
                                        value={option.name}
                                        onChange={(e) => handleOptionChange(optionIndex, 'name', e.target.value)}
                                        style={{ width: '50%' }} // This sets the input width to 50%
                                    />

                                    <div className="mb-0 d-flex flex-wrap">
                                        {option.values.map((value, valueIndex) => (
                                            <span
                                                key={valueIndex}
                                                className="badge bg-primary me-1 mb-1"
                                                style={{ fontSize: '0.8rem', padding: '6px' }}
                                            >
                                                {value}{' '}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-danger ms-1"
                                                    style={{
                                                        padding: '0px 4px',
                                                        fontSize: '0.5rem',
                                                        lineHeight: '12px',
                                                    }}
                                                    onClick={() => handleDeleteValue(optionIndex, valueIndex)}
                                                >
                                                    &times;
                                                </button>
                                            </span>
                                        ))}
                                    </div>

                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Add variant and press enter"
                                        value={tempValues[optionIndex] || ''}
                                        onChange={(e) => handleTempValueChange(optionIndex, e.target.value)}
                                        onKeyDown={(e) => handleOptionValuesChange(optionIndex, e)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};