// src/redux/slices/addProductSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, db, storage } from '../../config/firebase';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';

// Function to upload base64 image to Firebase Storage and return the URL
const uploadImageToStorage = async (base64Image, fileName) => {
    const storageRef = ref(storage, `productImages/${fileName}`);
    await uploadString(storageRef, base64Image, 'data_url');
    return await getDownloadURL(storageRef);
};

// Function to check and update the `isDefault` flag based on options
const updateIsDefaultFlag = (options) => {
    return (
        options?.length === 0 ||
        (options?.length === 1 &&
            options[0]?.name === 'Title' &&
            options[0]?.values?.[0] === 'Default Title')
    );
};

// Async thunk to add a new product to Firestore
export const addProductToFirestore = createAsyncThunk(
    'addProduct/addProductToFirestore',
    async (_, { getState, rejectWithValue }) => {
        try {
            const user = auth.currentUser;
            if (!user) {
                return rejectWithValue('No user logged in');
            }

            const state = getState();
            const product = state.addProduct.product;

            // Validate required fields
            if (
                !product.title ||
                !product.description ||
                !product.images ||
                product.images.length === 0 ||
                !product.taxStatus || 
                (product.taxStatus !== 'charge' && product.taxStatus !== 'none')
              ) {
                return rejectWithValue(
                  'Please fill in all required fields: title, description, tax, and at least one image.'
                );
              }

            // Fetch user's locationId and creatorName from Firestore
            const userDocRef = doc(db, 'users', user.uid);
            const userSnapshot = await getDoc(userDocRef);
            if (!userSnapshot.exists()) {
                return rejectWithValue('User not found');
            }

            const { locationId, creatorName } = userSnapshot.data();

            // Prepare image upload promises with folder based on creatorName
            const imageUploadPromises = product.images.map(async (image) => {
                if (image.base64) {
                    // Upload new image to a folder named after the creator
                    const folderPath = `productImages/${creatorName}/`;
                    return await uploadImageToStorage(image.base64, `${folderPath}${image.fileName}`);
                } else if (image.src) {
                    // Existing image URL, return the URL
                    return image.src;
                }
                throw new Error('Image does not have base64 data or a URL');
            });

            // Wait for all images to be uploaded and get their URLs
            const imageUrls = await Promise.all(imageUploadPromises);

            // Prepare the new product data
            const newProduct = {
                title: product.title,
                body_html: product.description,
                images: imageUrls,
                options: product.isDefault ? [] : product.options,
                variants: product.variants,
                generatedVariants: product.generatedVariants,
                isDefault: product.isDefault,
                created_at: new Date(),
                handle: product.handle || 'N/A',
                image: product.image || {},
                status: product.status || 'N/A',
                variant_ids: product.variant_ids || [],
                product_type: product.product_type || 'N/A',
                style: product.style || 'N/A',
                material: product.material || 'N/A',
                collection: product.collection || 'N/A',
                vendor: product.vendor || 'N/A',
                tags: product.tags || [],
                userId: user.uid,
                locationId,
                creatorName,
                taxStatus: product.taxStatus,
                weight: product.weight,
            };

            // Reference to the user's productsToShopify collection
            const productsCollectionRef = collection(db, 'users', user.uid, 'productsToShopify');

            // Add the new product to Firestore
            const docRef = await addDoc(productsCollectionRef, newProduct);

            // Update user's locationId and creatorName in Firestore (if needed)
            await updateDoc(userDocRef, { locationId, creatorName });

            return { id: docRef.id, newProduct };
        } catch (error) {
            // Handle any other errors
            return rejectWithValue(error.message || 'An error occurred while adding the product.');
        }
    }
);

const initialState = {
    product: {
        id: null,
        title: '',
        description: '',
        images: [],
        options: [],
        variants: [],
        generatedVariants: [],
        isDefault: true,
        handle: '',
        image: {},
        status: '',
        variant_ids: [],
        product_type: '',
        style: '',
        material: '',
        collection: '',
        vendor: '',
        tags: [],
        taxStatus: '',
        weight: '',
    },
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    // Add predetermined values for the four inputs
    presetOptions: {
        styles: [
            { id: 1, name: 'Casual', value: 'Casual' },
            { id: 2, name: 'Formal', value: 'Formal' },
            { id: 3, name: 'Sport', value: 'Sport' },
            // Add more styles as needed
        ],
        productTypes: [
            { id: 1, name: 'Shirt', value: 'Shirt' },
            { id: 2, name: 'Pants', value: 'Pants' },
            { id: 3, name: 'Shoes', value: 'Shoes' },
            // Add more product types as needed
        ],
        materials: [
            { id: 1, name: 'Cotton', value: 'Cotton' },
            { id: 2, name: 'Leather', value: 'Leather' },
            { id: 3, name: 'Synthetic', value: 'Synthetic' },
            // Add more materials as needed
        ],
        collections: [
            { id: 1, name: 'Summer 2023', value: 'Summer 2023' },
            { id: 2, name: 'Winter 2023', value: 'Winter 2023' },
            { id: 3, name: 'Limited Edition', value: 'Limited Edition' },
            // Add more collections as needed
        ],
    },
};

const addProductSlice = createSlice({
    name: 'addProduct',
    initialState,
    reducers: {
        // Action to locally update product data in the store
        updateProductField: (state, action) => {
            const { field, value } = action.payload;
            state.product[field] = value;

            // If the options are updated, recalculate the isDefault flag
            if (field === 'options') {
                state.product.isDefault = updateIsDefaultFlag(value);
            }
        },
        // Action to reset the product state
        resetProductState: (state) => {
            state.product = { ...initialState.product };
            state.status = 'idle';
            state.error = null;
        },
        // Action to set an error from components
        setError: (state, action) => {
            state.error = action.payload;
        },
        // Action to clear the error
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addProductToFirestore.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addProductToFirestore.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.product = { ...initialState.product };
            })
            .addCase(addProductToFirestore.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            });
    },
});

export const {
    updateProductField,
    resetProductState,
    setError,
    clearError,
} = addProductSlice.actions;

export default addProductSlice.reducer;