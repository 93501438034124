import React from 'react';
import { Link } from 'react-router-dom';

export const CreatorShopSection = () => {
  return (
    <section
      id="section3"
      className="p-3 mb-5 rounded shadow-sm"
      style={{ scrollMarginTop: "65px", backgroundColor: "rgba(248, 249, 250, 0.8)" }}
    >
      <div className="container">
        <h2 className="display-5">My Shop</h2>
        <p className="lead">
          Get started with Cay Collective by editing your shop shown on CayCollective.com
        </p>
        <Link to="/creator-shop" className="btn btn-info" style={{ color: 'white' }}>
        Edit
        </Link>
      </div>
    </section>
  );
};