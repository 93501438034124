import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../css/allCSS.css";
import Dashboard from '../components/Dashboard'; // Import your components here
import SidebarNav from "../components/sidebarNav";  // Adjust the import path accordingly
import AddProductsSection from "../components/addProduct/addProduct";  // Adjust the import path accordingly
import { CreatorShopSection } from "../components/creatorShopSection";  // Adjust the import path accordingly
import EditProductsSection from "../components/editProduct/editProducts";  // Adjust the import path accordingly
import ContentSection from "../components/contentSection";  // Adjust the import path accordingly
import OrdersSection from "../components/ordersSection";  // Adjust the import path accordingly
import PaymentsSection from "../components/paymentsSection";  // Adjust the import path accordingly
import ReturnsSection from "../components/returnsSection";  // Adjust the import path accordingly

export const Main = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
      const scrollFraction = position / maxScroll;
      setScrollPosition(scrollFraction);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundStyle = {
    backgroundColor: `rgba(33, 37, 41, ${Math.min(0.1 + scrollPosition * 1, 1000)})`, // Adjusted opacity transition
    transition: "background-color s ease",
  };

  return (
    <div className="d-flex">
      <SidebarNav/>
      <div className="flex-grow-1 main-content" style={backgroundStyle}>
        {/* Introduction Section */}
        <div
  id="introduction"
  className="py-5 mb-5 text-white rounded shadow-sm"
  style={{ backgroundColor: '#232323', color: '#ffffff' }}>          <div className="container text-center">
            <h1 className="display-4 fw-bold">Welcome to  the Cay Creator site!</h1>
            <p className="lead">v1.1</p>
            <p className="lead">This web-app is currently in development. Stay tuned!</p>
          </div>
        </div>
        <Dashboard/>
        <AddProductsSection/>
        <OrdersSection/>
        <ContentSection/>
        <EditProductsSection/>
        <PaymentsSection/>
        <CreatorShopSection/>
        <ReturnsSection/>
        <p className="display-10 fw-bold text-left text-white">Made with <span className="text-danger">♥</span> for Art</p>      
      </div>
    </div>
  );
};