import { signInWithPopup, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { auth, provider } from '../config/firebase'; // Removed Firestore imports as they are not needed here
import { useNavigate } from 'react-router-dom';
import '../css/Login.css';
import logo from '../media/cay_logo.png';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FcGoogle } from 'react-icons/fc';

export const Login = () => {
    const navigate = useNavigate();

    const signInWithGoogle = async () => {
        try {
            if (isMobile) {
                await signInWithRedirect(auth, provider);
            } else {
                const result = await signInWithPopup(auth, provider);
                navigate("/"); // AppContent will handle further logic
            }
        } catch (error) {
            console.error("Error signing in with Google:", error.message);
        }
    };

    useEffect(() => {
        const checkRedirect = async () => {
            try {
                const result = await getRedirectResult(auth);
                if (result && result.user) {
                    navigate("/"); // AppContent will handle further logic
                }
            } catch (error) {
                console.error("Error getting redirect result:", error.message);
            }
        };

        if (isMobile) {
            checkRedirect();
        }
    }, []);

    return (
        <div className="login-container-login">
            <div className="logo-container-login">
                <img src={logo} alt="Company Logo" />
            </div>
            <h3>v1.1</h3>
            
            <button 
                className="google-signin-btn" 
                onClick={signInWithGoogle}
                aria-label="Sign in with Google"
            >
                <FcGoogle size={24} style={{ marginRight: '8px' }} />
                Sign in with Google
            </button>
        </div>
    );
};

// Injected Styles for Button Hover Effect
// Remove or modify your existing styleSheet code and replace it with this:
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
.google-signin-btn {
    background-color: #1a1a1a; /* Almost black */
    color: #fff;
    border: none !important;
    border-radius: 0 !important; /* Force 0 radius */
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Roc Grotesk', sans-serif; /* Apply custom font */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition */
}

.google-signin-btn:hover {
    background-color: #333333; /* Slightly lighter grey on hover */
}
`;
document.head.appendChild(styleSheet);