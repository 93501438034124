import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Provider, useDispatch } from 'react-redux';
import store from './redux/store.js';
import './css/allCSS.css';
import './css/App.css';

import { Main } from './pages/main';
import { Login } from './pages/login';
import { ApplyFirst as Apply } from './pages/applyFirst';
import { Navbar } from './components/navbar';
import { Footer } from './components/footer';
import { Loader } from './components/loaders/loader';
import { Product } from './pages/product';
import { Orders } from './pages/orders';
import { CookieBanner } from './components/cookie';
import { Admin } from './pages/admin';
import { AllProductsPage } from './pages/allProductsPage';
import { EditProductPage } from './pages/editProductPage';
import { AddProductPage } from './pages/addProductPage';
import { ContentPage } from './pages/contentPage.js';
import { CreatorShopPage } from './pages/creatorShopPage';
import { UnderDevelopmentPage } from './pages/underDevelopmentPage';
import { TermsOfServicePage } from './pages/TermsOfServicePage';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import AccountStatusPage from './pages/AccountStatusPage';
import { FeedbackWidget } from './components/FeedbackWidget'; // <-- NEW IMPORT

import { fetchUserData, setupUserListener } from './redux/slices/userSlice';
import { fetchOrders, setupOrdersListener } from './redux/slices/ordersSlice';
import { fetchProducts, setupProductsListener } from './redux/slices/productsSlice';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from './config/firebase';

const auth = getAuth();

// Reusable Protected Route Component
function ProtectedRoute({ element: Component, user, requireVerified }) {
    // Pull from localStorage to determine status
    const verified = localStorage.getItem("verified") === "true";
    const applications = localStorage.getItem("applications") === "true";

    // If the route requires verified access
    if (requireVerified) {
        // User is not verified
        if (!verified) {
            // If they've applied (application is pending)
            if (applications) {
                return <Navigate to="/account-status" />;
            } else {
                // If user has NOT applied
                return <Navigate to="/apply" />;
            }
        }
    }

    // For any route that doesn't specifically require verified,
    // just make sure the user is logged in
    return user ? Component : <Navigate to="/login" />;
}

function AppContent() {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasCheckedFirestore, setHasCheckedFirestore] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                if (!hasCheckedFirestore) {
                    const userRef = doc(db, "users", currentUser.uid);
                    const userSnap = await getDoc(userRef);

                    if (!userSnap.exists()) {
                        await setDoc(userRef, {
                            uid: currentUser.uid,
                            gname: currentUser.displayName || "Unknown User",
                            email: currentUser.email || "No Email Provided",
                            verified: false,
                        });
                        localStorage.setItem("verified", "false");
                        localStorage.setItem("applications", "false");
                    } else {
                        const userData = userSnap.data();
                        localStorage.setItem("verified", userData.verified ? "true" : "false");
                        localStorage.setItem("applications", userData.applications ? "true" : "false");
                    }

                    setHasCheckedFirestore(true);
                }

                setUser(currentUser);

                // Dispatch your Redux thunks to sync data
                dispatch(fetchUserData());
                dispatch(setupUserListener());
                dispatch(fetchOrders());
                dispatch(setupOrdersListener());
                dispatch(fetchProducts());
                dispatch(setupProductsListener());
            } else {
                setUser(null);
                setHasCheckedFirestore(false);
                localStorage.removeItem("verified");
                localStorage.removeItem("applications");
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [dispatch, hasCheckedFirestore]);

    if (loading) return <Loader />;

    return (
        <div className="App">
            {user && <CookieBanner />}
            {user && <Navbar />}

            <Routes>
                <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
                <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

                {/* Routes that require a verified user will use `requireVerified={true}` */}
                <Route path="/" element={<ProtectedRoute element={<Main />} user={user} requireVerified={true} />} />
                <Route path="/apply" element={<ProtectedRoute element={<Apply />} user={user} requireVerified={false} />} />
                <Route path="/product" element={<ProtectedRoute element={<Product />} user={user} requireVerified={true} />} />
                <Route path="/orders" element={<ProtectedRoute element={<Orders />} user={user} requireVerified={true} />} />
                <Route path="/admin" element={<ProtectedRoute element={<Admin />} user={user} requireVerified={true} />} />
                <Route path="/allProductsPage" element={<ProtectedRoute element={<AllProductsPage />} user={user} requireVerified={true} />} />
                <Route path="/edit-product/:id" element={<ProtectedRoute element={<EditProductPage />} user={user} requireVerified={true} />} />
                <Route path="/add-products" element={<ProtectedRoute element={<AddProductPage />} user={user} requireVerified={true} />} />
                <Route path="/content" element={<ProtectedRoute element={<ContentPage />} user={user} requireVerified={true} />} />

                {/* Under-Development / placeholder routes */}
                <Route path="/creator-shop" element={<ProtectedRoute element={<UnderDevelopmentPage />} user={user} requireVerified={true} />} />
                <Route path="/returns" element={<ProtectedRoute element={<UnderDevelopmentPage />} user={user} requireVerified={true} />} />
                <Route path="/payments" element={<ProtectedRoute element={<UnderDevelopmentPage />} user={user} requireVerified={true} />} />

                {/* Account Status Page: no need for verified access, but must be logged in */}
                <Route path="/account-status" element={<ProtectedRoute element={<AccountStatusPage />} user={user} requireVerified={false} />} />
            </Routes>

            <Footer />
            {user && <FeedbackWidget user={user} />}

        </div>
    );
}

function App() {
    return (
        <Provider store={store}>
            <Router>
                <AppContent />
            </Router>
        </Provider>
    );
}

export default App;