// src/pages/EditProductPage.js

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProducts,
  updateProduct,
  updateProductInFirestore,
} from '../redux/slices/productsSlice'; // Adjust the path as needed
import { useForm } from 'react-hook-form';
import { SkeletonProductLoader } from '../components/loaders/SkeletonProductLoader.js'; // Import skeleton loader
import { ProductImageManager } from '../components/editProduct/productImageManager.js'; // Import image manager
import { OptionsManager } from '../components/editProduct/optionsManager.js'; // Import options manager
import { VariantsManager } from '../components/editProduct/variantsManager.js'; // Import variants manager
import { BsImage } from 'react-icons/bs'; // Import the image icon
import ImageLoader from '../components/loaders/imageLoader';
import DeleteButton from '../components/deleteProductButton'; // Import DeleteButton
import { SuccessPopup } from '../components/editProduct/SuccessEditPopup.js'; // Import the SuccessPopup component

// Import React Quill and Quill for rich text editing
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import React Quill styles

export const EditProductPage = () => {
  const { id } = useParams(); // Get product ID from the URL
  const dispatch = useDispatch(); // Set up dispatch
  const productsState = useSelector((state) => state.products); // Get products state from Redux store
  const { products = [], status } = productsState; // Destructure the products and status from the state

  const product = products.find((product) => product.id === id); // Find the specific product by ID
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: product || {}, // Set the initial form values with the product data or an empty object
  });

  const [currentIndex, setCurrentIndex] = useState(0); // Track the current image index
  const [quantity, setQuantity] = useState(''); // State to track quantity
  const [price, setPrice] = useState(''); // State to track price
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State to control the popup visibility
  const [error, setError] = useState(''); // Local state for error messages

  const hasDefaultVariant = product?.isDefault;

  // Create a ref for ReactQuill
  const quillRef = useRef(null);

  // Define the inline CSS styles for the Quill editor
  const quillEditorStyles = `
    .product-description-editor .ql-editor p {
      margin-bottom: 1rem; /* Adjust as needed */
      margin-top: 0; /* Optional */
    }
  `;

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchProducts()); // Fetch products if not yet loaded
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (product) {
      setValue('title', product.title);
      setValue('description', product.description);

      // Set the initial quantity from the first variant
      const totalQuantity = product.variants?.[0]?.inventory_quantity || '';
      setQuantity(totalQuantity);

      // Set the initial price from the first variant
      const variantPrice = product.variants?.[0]?.price || '';
      setPrice(variantPrice);
    }
  }, [product, setValue]);

  // Inject the CSS styles into the document head
  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.type = 'text/css';
    styleTag.appendChild(document.createTextNode(quillEditorStyles));
    document.head.appendChild(styleTag);

    // Cleanup function to remove the style tag on unmount
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

  // Add custom Shift+Enter binding to Quill editor
  useEffect(() => {
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor();

      // Remove existing Shift+Enter binding if any
      editor.keyboard.bindings[13] = editor.keyboard.bindings[13].filter(
        (binding) => !binding.shiftKey
      );

      // Add custom Shift+Enter binding
      editor.keyboard.addBinding(
        {
          key: 13, // Enter key
          shiftKey: true,
        },
        function (range, context) {
          // Insert a line break
          editor.insertText(range.index, '\n', Quill.sources.USER);
          editor.setSelection(range.index + 1, Quill.sources.SILENT);
        }
      );
    }
  }, [quillRef]);

  // Define the modules for React Quill
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    clipboard: {
      matchVisual: false, // Disable the matchVisual option
    },
    // No need to add keyboard bindings here since we added them in useEffect
  };

  // Function to handle local state updates for product fields (Redux state)
  const handleFieldChange = (field, value) => {
    dispatch(updateProduct({ id: product.id, field, value })); // Dispatch update to Redux store
  };

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity); // Update the local state

    const quantityInt = parseInt(newQuantity, 10);

    // Update the first variant's quantity
    let updatedVariants = [...(product.variants || [])];
    if (updatedVariants.length === 0) {
      updatedVariants.push({});
    }
    updatedVariants[0] = {
      ...updatedVariants[0],
      inventory_quantity: isNaN(quantityInt) ? '' : quantityInt,
    };

    // Dispatch the updated variants to Redux
    dispatch(
      updateProduct({ id: product.id, field: 'variants', value: updatedVariants })
    );
  };

  const handlePriceChange = (newPrice) => {
    setPrice(newPrice); // Update the local state

    // Update the first variant's price
    let updatedVariants = [...(product.variants || [])];
    if (updatedVariants.length === 0) {
      updatedVariants.push({});
    }
    updatedVariants[0] = {
      ...updatedVariants[0],
      price: newPrice,
    };

    // Dispatch the updated variants to Redux
    dispatch(
      updateProduct({ id: product.id, field: 'variants', value: updatedVariants })
    );
  };

  const onSubmit = (data) => {
    // Clear previous messages
    setError('');

    // Validate required fields
    const { title, description, images } = product;
    if (!title || !description || !images || images.length === 0) {
      setError(
        'Please fill in all required fields: title, description, and at least one image.'
      );
      return;
    }

    // Proceed to update product
    dispatch(updateProductInFirestore({ id: product.id }))
      .unwrap()
      .then(() => {
        console.log('Product successfully updated in Firestore.');
        setShowSuccessPopup(true); // Show the success popup
      })
      .catch((error) => {
        console.error('Error updating product:', error);
        setError(error.message || 'Error updating product. Please try again.');
      });
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < (product.images?.length || 0) - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
    window.location.href = '/'; // Redirect to the main page or desired location
  };

  if (status === 'loading') {
    return (
      <div
        className="container mt-4 d-flex flex-column"
        style={{ paddingBottom: '100px' }}
      >
        <div className="row flex-grow-1">
          <SkeletonProductLoader />
        </div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          Product not found
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      {/* Include the SuccessPopup component */}
      <SuccessPopup show={showSuccessPopup} onClose={handleClosePopup} />

      {/* Header: Edit Product and Save button */}
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1>Edit Product</h1>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit(onSubmit)}
              disabled={status === 'loading'}
            >
              {status === 'loading' ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        {/* Product Image Carousel */}
        <div className="col-md-4 d-flex flex-column">
          <div
            className="card mb-4 flex-grow-1"
            style={{
              borderRadius: '10px',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            {/* Display ImageLoader when loading */}
            {status === 'loading' ? (
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '10px',
                  height: '300px',
                }}
              >
                <ImageLoader />
              </div>
            ) : product.images && product.images.length > 0 ? (
              <div
                id="productCarousel"
                className="carousel slide"
                data-bs-touch="true"
              >
                <div className="carousel-inner">
                  {product.images.map((image, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${
                        index === currentIndex ? 'active' : ''
                      }`}
                      style={{ position: 'relative' }}
                    >
                      {image.base64 || image.src ? (
                        <img
                          src={image.base64 || image.src}
                          className="d-block w-100"
                          alt={`Product image ${index + 1}`}
                          style={{
                            objectFit: 'cover',
                            height: '300px',
                            zIndex: '-1',
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '10px',
                            height: '300px',
                          }}
                        >
                          <ImageLoader />
                        </div>
                      )}
                      <div
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          color: 'white',
                          padding: '5px 10px',
                          borderRadius: '5px',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          zIndex: 1,
                        }}
                      >
                        {index + 1} / {product.images.length}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  onClick={handlePrev}
                  disabled={currentIndex === 0} // Disable if on the first image
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  onClick={handleNext}
                  disabled={
                    currentIndex === (product.images.length || 0) - 1
                  } // Disable if on the last image
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            ) : (
              // Display the icon with grey background when no images
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '10px 10px 0px 0px',
                  height: '300px',
                  backgroundColor: '#e0e0e0', // Darker grey background
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BsImage size={80} color="#6c757d" />
              </div>
            )}

            <div className="card-body">
              <h5 className="card-title">{product.title || 'Untitled'}</h5>
              <div
                className="card-text"
                dangerouslySetInnerHTML={{
                  __html: product.description || 'No description available',
                }}
              ></div>
            </div>
          </div>
        </div>

        {/* Product Form */}
        <div className="col-md-8 d-flex flex-column">
          {/* Display Error Messages */}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <form className="d-flex flex-column flex-grow-1">
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Product Name
              </label>
              <input
                {...register('title')}
                id="title"
                className="form-control"
                onChange={(e) => handleFieldChange('title', e.target.value)} // Update Redux state
              />
            </div>

            <div className="mb-3 d-flex align-items-center">
            <div className="me-2" style={{ flex: '1', position: 'relative' }}>
                <label htmlFor="price" className="form-label">
                  Price
                </label>
                <input
                  type="text"
                  value={hasDefaultVariant ? price : ''}
                  id="price"
                  className="form-control"
                  onChange={(e) => handlePriceChange(e.target.value)}
                  disabled={!hasDefaultVariant}
                  placeholder={
                    !hasDefaultVariant ? 'See Options' : ''
                  }
                  style={{ paddingRight: '50px' }} // Add padding to avoid overlapping with "SEK"
                />
                <span
                  style={{
                    position: 'absolute',
                    top: '75%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    color: '#6c757d',
                    fontSize: '0.9rem',
                  }}
                >
                  SEK
                </span>
              </div>
              <div style={{ width: '100px' }}>
                <label htmlFor="quantity" className="form-label">
                  Quantity
                </label>
                <input
                  type="number"
                  value={hasDefaultVariant ? quantity : ''}
                  id="quantity"
                  className="form-control"
                  style={{ width: '100%' }}
                  onChange={(e) => handleQuantityChange(e.target.value)}
                  disabled={!hasDefaultVariant}
                />
              </div>
            </div>

            <div className="mt-3">

        {!product.taxable ? (
          <div className="alert alert-success d-flex align-items-center" role="alert">
                <i className="bi bi-check-circle-fill me-2" style={{ fontSize: '1.2rem' }}></i>
                This product is taxable.
              </div>
            ) : (
              <div className="alert alert-secondary d-flex align-items-center" role="alert">
                <i className="bi bi-info-circle-fill me-2" style={{ fontSize: '1.2rem' }}></i>
                This product is not taxable.
              </div>
            )}
          </div>
          
            <div
              className="mb-4"
              style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
            >
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <div style={{ flexGrow: 1 }}>
                <ReactQuill
                  ref={quillRef}
                  className="product-description-editor"
                  value={product.description || ''}
                  onChange={(value) => handleFieldChange('description', value)}
                  modules={modules}
                  formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                  style={{
                    flexGrow: 1,
                    borderRadius: '10px',
                    height: '99%',
                    paddingBottom: '40px',
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ProductImageManager />
      <OptionsManager />
      <VariantsManager />
      <DeleteButton />
    </div>
  );
};