import React from "react";
import {
  BsBarChartLine,
  BsPlusSquare,
  BsBoxSeam,
  BsCardImage,
  BsPencilSquare,
  BsCashCoin,
  BsBook,
  BsArrowDownUp,
} from "react-icons/bs";

const SidebarNav = () => {
  return (
    <nav
      className="nav flex-column bg-light vh-100 p-3 d-none d-sm-block shadow-sm"
      style={{ width: "200px", position: "fixed" }}
    >
      <a className="nav-link active" href="#Dashboard" style={{ color: "inherit" }}>
        <BsBarChartLine className="me-2" /> Dashboard
      </a>
      <a className="nav-link" href="#section2" style={{ color: "inherit" }}>
        <BsPlusSquare className="me-2" /> Add Products
      </a>
      <a className="nav-link" href="#section7" style={{ color: "inherit" }}>
        <BsBoxSeam className="me-2" /> Orders
      </a>
      <a className="nav-link" href="#section6" style={{ color: "inherit" }}>
        <BsCardImage className="me-2" /> Content
      </a>
      <a className="nav-link" href="#section5" style={{ color: "inherit" }}>
        <BsPencilSquare className="me-2" /> Edit Products
      </a>
      <a className="nav-link" href="#section8" style={{ color: "inherit" }}>
        <BsCashCoin className="me-2" /> Payments
      </a>
      <a className="nav-link" href="#section3" style={{ color: "inherit" }}>
        <BsBook className="me-2" /> My Shop
      </a>
      <a className="nav-link" href="#section9" style={{ color: "inherit" }}>
        <BsArrowDownUp className="me-2" /> Returns
      </a>
    </nav>
  );
};

export default SidebarNav;