import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductField } from '../../redux/slices/addProductSlice'; // Adjust the path as needed
import { v4 as uuidv4 } from 'uuid'; // To generate unique keys for options
import { BsInfoCircleFill} from 'react-icons/bs'; // Import Bootstrap Info Icon

export const AddOptionsManager = () => {
    const dispatch = useDispatch();
    const product = useSelector((state) => state.addProduct.product); // Access product from addProduct slice
    const options = product?.options || []; // Directly use options from Redux, no local state
    const [tempValues, setTempValues] = useState({}); // Temporary input values for each option

    const handleAddOption = () => {
        if (options.length < 3) {
            const newOptions = [...options, { id: uuidv4(), name: '', values: [] }];
            dispatch(updateProductField({ field: 'options', value: newOptions }));
        }
    };

    const handleOptionChange = (optionIndex, field, value) => {
        const updatedOptions = options.map((option, index) =>
            index === optionIndex ? { ...option, [field]: value.charAt(0).toUpperCase() + value.slice(1) } : option
        );
        dispatch(updateProductField({ field: 'options', value: updatedOptions }));
    };

    const handleTempValueChange = (optionIndex, value) => {
        setTempValues({ ...tempValues, [optionIndex]: value });
    };

    const handleOptionValuesChange = (optionIndex, event) => {
        const value = event.target.value.trim();
        const key = event.key;

        if (key === ',' || key === 'Enter') {
            event.preventDefault();
            if (value) {
                const updatedOptions = options.map((option, index) =>
                    index === optionIndex
                        ? { ...option, values: [...option.values, value.charAt(0).toUpperCase() + value.slice(1)] }
                        : option
                );
                dispatch(updateProductField({ field: 'options', value: updatedOptions }));
                setTempValues({ ...tempValues, [optionIndex]: '' });
            }
        }
    };

    const handleDeleteValue = (optionIndex, valueIndex) => {
        const updatedOptions = options.map((option, index) =>
            index === optionIndex
                ? { ...option, values: option.values.filter((_, i) => i !== valueIndex) }
                : option
        );
        dispatch(updateProductField({ field: 'options', value: updatedOptions }));
    };

    const handleDeleteOption = (optionIndex) => {
        const updatedOptions = options.filter((_, index) => index !== optionIndex);
        dispatch(updateProductField({ field: 'options', value: updatedOptions }));
    };

    if (!product) return <div>Loading product data...</div>;

    return (
        <div className="container p-4 mb-4 rounded" style={{ backgroundColor: '#f0f0f0' }}>
            <div className="mb-3 d-flex align-items-center">
                <button
                    className="btn btn-primary me-2"
                    onClick={handleAddOption}
                    disabled={options.length >= 3} // Disable the button when 3 options are added
                >
                    Add Option
                </button>

                {/* Info Icon with Tooltip */}
                <span
                    className="tooltip-hover text-muted d-flex align-items-center"
                    data-tooltip="Options allow you to define variations like size, color, or material. You can add up to 3 options."
                >
                    <BsInfoCircleFill size={18} className="ms-1" />
                </span>
            </div>

            <div className="row g-0">
                {options.map((option, optionIndex) => (
                    <div key={option.id} className="col-sm-8 col-md-6 col-lg-4 p-1">
                        <div className="card shadow-sm position-relative">
                            <button
                                type="button"
                                className="btn btn-danger btn-sm position-absolute"
                                style={{
                                    top: '5px',
                                    right: '5px',
                                    width: '25px',
                                    height: '25px',
                                    borderRadius: '20%',
                                    padding: '0',
                                    fontSize: '0.7rem',
                                    zIndex: 1,
                                }}
                                onClick={() => handleDeleteOption(optionIndex)}
                            >
                                &times;
                            </button>

                            <div className="card-body">
                                <input
                                    type="text"
                                    className="form-control mb-1"
                                    placeholder='"Size"'
                                    value={option.name}
                                    onChange={(e) => handleOptionChange(optionIndex, 'name', e.target.value)}
                                    style={{ width: '50%' }} // This sets the input width to 50%
                                />

                                <div className="mb-0 d-flex flex-wrap">
                                    {option.values.map((value, valueIndex) => (
                                        <span
                                            key={valueIndex}
                                            className="badge bg-primary me-1 mb-1"
                                            style={{ fontSize: '0.8rem', padding: '6px' }}
                                        >
                                            {value}{' '}
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-danger ms-1"
                                                style={{
                                                    padding: '0px 4px',
                                                    fontSize: '0.5rem',
                                                    lineHeight: '12px',
                                                }}
                                                onClick={() => handleDeleteValue(optionIndex, valueIndex)}
                                            >
                                                &times;
                                            </button>
                                        </span>
                                    ))}
                                </div>

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type and press Enter"
                                    value={tempValues[optionIndex] || ''}
                                    onChange={(e) => handleTempValueChange(optionIndex, e.target.value)}
                                    onKeyDown={(e) => handleOptionValuesChange(optionIndex, e)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};