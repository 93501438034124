// src/components/PrintInvoiceLink.js
import React from 'react';
import cayLogo from '../media/cay_logo.png'; // Adjust path if needed

export const InvoiceGenerator = ({ order }) => {
  const handlePrintOrderSummary = (e) => {
    e.preventDefault();
    if (!order) return;

    // 1) Build "Seller" and "Buyer" data from the order
    const seller = order.assigned_location || {};
    const buyer = order.destination || {};

    // 2) Build line items HTML
    let computedTotal = 0;
    const lineItemsHTML = order.line_items
      .map((item) => {
        // Parse price as float, multiply by quantity
        const priceNum = parseFloat(item.price) || 0;
        const qty = item.quantity || 1;
        const subtotal = priceNum * qty;
        computedTotal += subtotal;

        return `
          <tr>
            <td>${item.vendor}</td>
            <td>${item.name}</td>
            <td>${qty}</td>
            <td>${priceNum.toFixed(2)}</td>
            <td>${subtotal.toFixed(2)}</td>
          </tr>
        `;
      })
      .join('');

    // If your stored 'order.total' is the final total, you can use that directly.
    // Otherwise use computedTotal from the line items:
    const finalTotal = computedTotal.toFixed(2);

    // 3) Construct the HTML
    const orderSummaryHTML = `
      <html>
      <head>
        <title>Order Summary #${order.id}</title>
        <style>
          @media print {
            body { margin: 0; padding: 0; }
          }
          body {
            font-family: Arial, sans-serif;
            color: #333;
            line-height: 1.6;
            margin: 20px;
          }
          .summary-container {
            max-width: 800px;
            margin: 0 auto;
          }
          .header, .footer {
            text-align: center;
            margin-bottom: 30px;
          }
          .header img {
            max-width: 140px;
            margin-bottom: 10px;
          }
          .summary-title {
            font-size: 24px;
            margin: 0;
            padding-bottom: 10px;
            border-bottom: 2px solid #ccc;
          }
          .address-section {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 30px;
          }
          .address-block {
            width: 45%;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 30px;
          }
          table, th, td {
            border: 1px solid #ddd;
          }
          th, td {
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f9f9f9;
          }
          .totals {
            text-align: right;
            margin-top: 20px;
          }
          .totals p {
            margin: 0;
            padding: 2px 0;
          }
          .footer small {
            color: #666;
          }
        </style>
      </head>
      <body>
        <div class="summary-container">
          <div class="header">
            <img src="${cayLogo}" alt="Cay Logo" />
            <h1 class="summary-title">ORDER SUMMARY</h1>
          </div>

          <!-- Seller & Buyer Addresses -->
          <div class="address-section">
            <div class="address-block">
              <h3>Seller:</h3>
              <p>
                ${seller.name || 'N/A'}<br/>
                ${seller.address1 || ''} ${seller.address2 || ''}<br/>
                ${seller.city || ''} ${seller.zip || ''}<br/>
                ${seller.country || ''}<br/>
                Phone: ${seller.phone || 'N/A'}
              </p>
            </div>
            <div class="address-block">
              <h3>Buyer:</h3>
              <p>
                ${
                  buyer.first_name || buyer.last_name
                    ? (buyer.first_name || '') + ' ' + (buyer.last_name || '')
                    : 'N/A'
                }<br/>
                ${buyer.address1 || ''} ${buyer.address2 || ''}<br/>
                ${buyer.city || ''} ${buyer.zip || ''}<br/>
                ${buyer.country || ''}<br/>
                Phone: ${buyer.phone || 'N/A'}
              </p>
            </div>
          </div>

          <!-- Order Meta Info -->
          <p><strong>Order ID:</strong> ${order.id}</p>
          <p><strong>Date:</strong> ${
            order.created_at !== 'N/A'
              ? new Date(order.created_at).toLocaleDateString()
              : 'N/A'
          }</p>

          <!-- Items Table -->
          <h3>Items</h3>
          <table>
            <thead>
              <tr>
                <th>Vendor</th>
                <th>Item</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              ${lineItemsHTML}
            </tbody>
          </table>

          <!-- Totals -->
          <div class="totals">
            <p><strong>Total:</strong> ${finalTotal}</p>
          </div>

          <!-- Footer -->
          <div class="footer">
            <p>Thank you for your business!</p>
            <small>Keep this order summary for your records.</small>
          </div>
        </div>

        <!-- Immediately trigger print on load -->
        <script>
          window.addEventListener('load', function() {
            window.print();
          });
        </script>
      </body>
      </html>
    `;

    // 4) Open a new tab, write the summary HTML, auto-print
    const printWindow = window.open('', '_blank');
    printWindow.document.write(orderSummaryHTML);
    printWindow.document.close();
  };

  return (
    <a
      href="#"
      onClick={handlePrintOrderSummary}
      style={{ textDecoration: 'underline', color: '#007bff', cursor: 'pointer' }}
    >
      Print
    </a>
  );
};