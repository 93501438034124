import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateProduct } from '../../redux/slices/productsSlice'; // Import updateProduct action
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';
import ImageLoader from '../loaders/imageLoader';

const ItemTypes = {
    IMAGE: 'image',
};

const DraggableImage = ({ image, index, moveImage, handleDelete }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (image.src || image.base64) {
            setIsLoading(false); // Image data is available, stop loading
        } else {
            setIsLoading(true); // No image data, show loader
        }
    }, [image.src, image.base64]);

    const [, ref] = useDrag({
        type: ItemTypes.IMAGE,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: ItemTypes.IMAGE,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveImage(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <div ref={(node) => ref(drop(node))} className="col-6 col-md-2 position-relative mb-3">
            <div
                className="card shadow-sm"
                style={{
                    width: '100%',
                    aspectRatio: '1/1', // Keep the placeholder square
                    borderRadius: '10px',
                    overflow: 'hidden', // Hide overflowing content
                    position: 'relative',
                }}
            >
                {isLoading ? (
                    <ImageLoader /> // Custom loader component while loading
                ) : (
                    <img
                        src={image.src || image.base64} // Use base64 or actual image URL
                        alt={`Product image ${index + 1}`}
                        className="d-block w-100"
                        style={{
                            objectFit: 'cover', // Ensure image fits the placeholder
                            width: '100%',
                            height: '100%',
                        }}
                    />
                )}
                <div
                    className="badge bg-dark position-absolute d-flex justify-content-center align-items-center"
                    style={{
                        top: '5px',
                        right: '5px',
                        width: '25px',
                        height: '25px',
                        fontSize: '0.8rem',
                    }}
                >
                    {index + 1}
                </div>
                <button
                    type="button"
                    className="btn btn-danger btn-sm position-absolute d-flex justify-content-center align-items-center"
                    style={{
                        top: '5px',
                        left: '5px',
                        width: '25px',
                        height: '25px',
                        borderRadius: '20%',
                        padding: '0',
                        fontSize: '0.5rem',
                    }}
                    onClick={() => handleDelete(index)}
                >
                    x
                </button>
            </div>
        </div>
    );
};

export const ProductImageManager = () => {
    const { id } = useParams(); // Get product ID from the URL
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products); // Get all products
    const product = products.find((product) => product.id === id); // Find the product by ID

    const [images, setImages] = useState(product?.images || []); // Initialize with Redux images
    const [isDragging, setIsDragging] = useState(false); // Track if an item is being dragged over the drop area

    // Sync the local images state with Redux product images when product changes
    useEffect(() => {
        setImages(product?.images || []); // Set the local state from Redux when product changes
    }, [product]);

    useEffect(() => {
        // Whenever images are updated, dispatch the updated images to Redux
        dispatch(updateProduct({ id: product.id, field: 'images', value: images }));
    }, [images, dispatch, product.id]);

    const handleDelete = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
    };

    const handleAddNewImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newImage = {
                    base64: reader.result, // The base64 image data
                    fileName: file.name, // Store the file name for future reference
                    fileType: file.type // Store the file type (e.g., image/jpeg)
                };
                setImages((prev) => [...prev, newImage]); // Add new image object to the combined array
            };
            reader.readAsDataURL(file); // Read the file as a Data URL for display
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newImage = {
                    base64: reader.result, // The base64 image data
                    fileName: file.name, // Store the file name for future reference
                    fileType: file.type // Store the file type (e.g., image/jpeg)
                };
                setImages((prev) => [...prev, newImage]); // Add the dropped image object
            };
            reader.readAsDataURL(file); // Read the file as a Data URL for display
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const moveImage = (fromIndex, toIndex) => {
        const updatedImages = [...images];
        const [movedImage] = updatedImages.splice(fromIndex, 1);
        updatedImages.splice(toIndex, 0, movedImage);
        setImages(updatedImages);
    };

    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <div className="container p-4 mb-4 rounded" style={{ backgroundColor: '#f0f0f0' }}>
                <div className="row g-3">
                    {images.map((image, index) => (
                        <DraggableImage
                            key={index}
                            image={image}
                            index={index}
                            moveImage={moveImage}
                            handleDelete={handleDelete}
                        />
                    ))}

                    {/* Placeholder for adding new images with drag-and-drop */}
                    <div className="col-6 col-md-2 mb-0">
                        <label
                            htmlFor="addImage"
                            className={`card d-flex justify-content-center align-items-center shadow-sm ${isDragging ? 'border border-primary' : ''}`}
                            style={{
                                width: '100%',
                                aspectRatio: '1/1',
                                backgroundColor: '#f8f9fa',
                                border: '2px dashed #ccc',
                                cursor: 'pointer',
                                borderRadius: '10px', // Add border radius to placeholder
                                overflow: 'hidden', // Ensure content doesn't overflow
                            }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                        >
                            <i className="bi bi-plus-lg text-muted" style={{ fontSize: '1.5rem' }}></i>
                            <span className="text-muted" style={{ fontSize: '2rem' }}>+</span>
                        </label>
                        <input
                            type="file"
                            id="addImage"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleAddNewImage}
                        />
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};