import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, db } from '../../config/firebase';
import { doc, getDoc, onSnapshot } from "firebase/firestore";

// Thunk to fetch user data
export const fetchUserData = createAsyncThunk('user/fetchUserData', async (_, { getState }) => {
    const { status } = getState().user;
    if (status === 'succeeded') return; // Prevent re-fetching if already succeeded

    const user = auth.currentUser;
    if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
            return userDoc.data();
        }
    }
    return {};
});

// Function to set up a Firestore listener
export const setupUserListener = () => (dispatch) => {
    const user = auth.currentUser;
    if (user) {
        const userDocRef = doc(db, 'users', user.uid);

        // Listen for real-time updates to the user's document
        const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                dispatch(fetchUserData.fulfilled(userData)); // Dispatch the fetched data

                // Update localStorage when 'verified' or 'applications' changes
                localStorage.setItem("verified", userData.verified ? "true" : "false");
                localStorage.setItem("applications", userData.applications ? "true" : "false");
            }
        });

        // Optional: return the unsubscribe function for cleanup if needed
        return unsubscribe;
    }
};

const initialState = {
    creatorName: null,
    nextPaymentAmount: null,
    nextPaymentDate: null,
    unfulfilledOrders: null,
    ordersThisMonth: null,
    pendingReturns: null,
    returnsThisMonth: null,
    verified: null,
    verificationDate: null,
    applications: null,
    fulfilledOrders: null,
    totalOrders: null,
    email: null,
    status: 'idle', // idle | loading | succeeded | failed
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                if (state.status === 'succeeded') return; // Skip loading state if already succeeded
                state.status = 'loading';
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Merge fetched user data into state
                Object.assign(state, action.payload);
            })
            .addCase(fetchUserData.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default userSlice.reducer;