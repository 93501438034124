import React, { useState, useEffect } from 'react';

export const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('hasAcceptedCookies');
    if (!hasAcceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('hasAcceptedCookies', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#232323', // Darker background
        color: '#ffffff', // White text
        padding: '20px',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        boxShadow: '0 -2px 5px rgba(0,0,0,0.5)',
      }}
    >
      <p style={{ margin: '0 0 10px', fontSize: '16px' }}>
        We use cookies to ensure you get the best experience on our website. By using our site, you agree to our use of cookies.
      </p>
      <div style={{ marginBottom: '10px' }}>
        <a
          href="/privacy-policy"
          style={{
            color: '#007BFF', // Startup blue color
            textDecoration: 'underline',
            fontSize: '14px',
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </div>
      <button
        onClick={handleAccept}
        style={{
          backgroundColor: '#007BFF', // Startup blue button color
          color: '#ffffff',
          border: 'none',
          borderRadius: '5px',
          padding: '10px 20px',
          fontSize: '14px',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#0056b3')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#007BFF')}
      >
        Got it!
      </button>
    </div>
  );
};