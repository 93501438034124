import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SkeletonProductLoader } from '../components/loaders/SkeletonProductLoader'; // Import the skeleton loader
import { BsPencilSquare } from "react-icons/bs"; // Import the edit icon

export const AllProductsPage = () => {
    const productsState = useSelector((state) => state.products);
    const { products = [], status = 'idle' } = productsState;

    if (status === 'loading') {
        return (
            <div className="container mt-4 d-flex flex-column" style={{ paddingBottom: '100px' }}>
                <div className="row flex-grow-1">
                    <SkeletonProductLoader />
                </div>
            </div>
        );
    }

    if (status === 'failed') {
        console.log('Failed to load products.');
        return <div>Failed to load products.</div>;
    }

    return (
        <div className="container mt-4 d-flex flex-column" style={{ paddingBottom: '100px' }}>
            <div className="row flex-grow-1">
                {products.length > 0 ? (
                    products.map((product) => (
                        <div key={product.id} className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4">
                            <div className="card h-100 shadow-sm position-relative">
                                <Link to={`/edit-product/${product.id}`} className="text-decoration-none text-dark">
                                    {/* Image with overlay */}
                                    <div className="position-relative">
                                        <img 
                                            src={product.image?.src || 'https://via.placeholder.com/150'} 
                                            className="card-img-top rounded-top" 
                                            alt={product.title} 
                                            style={{ objectFit: 'cover', height: '200px' }} 
                                        />
                                        {/* Edit Icon Overlay */}
                                        <div
                                            className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                                            style={{
                                                top: 0,
                                                left: 0,
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                color: "white",
                                                opacity: 0,
                                                transition: "opacity 0.3s ease",
                                            }}
                                            onMouseEnter={(e) => (e.currentTarget.style.opacity = 1)}
                                            onMouseLeave={(e) => (e.currentTarget.style.opacity = 0)}
                                        >
                                            <BsPencilSquare size={30} />
                                        </div>
                                    </div>
                                </Link>
                                {/* Card Body */}
                                <div className="card-body">
                                    <h5 className="card-title">{product.title}</h5>
                                    <p className="card-text">
                                        {product.price ? `SEK ${product.price}` : 'Price not available'}
                                    </p>
                                </div>
                                {/* Edit Icon Always Bottom-Right */}
                                <BsPencilSquare
                                    size={20}
                                    className="position-absolute text-muted"
                                    style={{
                                        bottom: '10px',
                                        right: '10px',
                                        cursor: 'pointer',
                                    }}
                                    title="Edit Price"
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <SkeletonProductLoader />
                )}
            </div>
        </div>
    );
};