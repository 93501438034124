import React from 'react';

export const PrivacyPolicyPage = () => {
  // -- Styles (mirroring the TOS design) --
  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '2rem 1rem',
  };

  const sectionStyle = {
    marginBottom: '2rem',
  };

  const headingStyle = {
    marginBottom: '0.5rem',
    fontWeight: 'bold',
  };

  const subHeadingStyle = {
    marginBottom: '0.5rem',
    fontWeight: 'bold',
    fontSize: '1rem',
  };

  const paragraphStyle = {
    marginBottom: '1rem',
    lineHeight: '1.6',
  };

  const listStyle = {
    paddingLeft: '1.2rem',
    marginBottom: '1rem',
  };

  const listItemStyle = {
    marginBottom: '0.5rem',
  };

  return (
    <div className="container my-5" style={containerStyle}>
      <h2 style={{ textAlign: 'center', marginBottom: '2rem' }}>Privacy Policy</h2>
      
      {/* Last Updated Date */}
      <p style={paragraphStyle}>
        <strong>Last Updated:</strong> 17 Jan 2025
      </p>

      {/* Introduction */}
      <p style={paragraphStyle}>
        Welcome to <strong>Cay Collective</strong> (“we,” “us,” or “our”). Your
        privacy is important to us. This Privacy Policy explains how we collect,
        use, and protect your personal information when you use our online
        marketplace platform (the “Marketplace”). By accessing or using the
        Marketplace, you agree to the terms outlined below. If you do not agree
        to these terms, please do not use the Marketplace.
      </p>

      {/* Section 1: Scope */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>1. Scope</h3>
        <p style={paragraphStyle}>
          This Privacy Policy applies to the personal information we collect
          through the Marketplace and any related services, tools, or
          communication channels. It does not apply to any third-party services
          or websites you may access through the Marketplace.
        </p>
      </div>

      {/* Section 2: Information We Collect */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>2. Information We Collect</h3>

        <h4 style={subHeadingStyle}>2.1 Personal Information</h4>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            Contact details (name, email address, phone number)
          </li>
          <li style={listItemStyle}>Billing and shipping addresses</li>
          <li style={listItemStyle}>Payment information</li>
          <li style={listItemStyle}>Any other information you provide to us</li>
        </ul>

        <h4 style={subHeadingStyle}>2.2 Usage Data</h4>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            Log files, IP addresses, browser type, operating system
          </li>
          <li style={listItemStyle}>Pages viewed and time spent on our site</li>
          <li style={listItemStyle}>
            Other diagnostic data to analyze Marketplace performance
          </li>
        </ul>
      </div>

      {/* Section 3: How We Use Your Information */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>3. How We Use Your Information</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Provide Services:</strong> To process your transactions,
            manage your account, and deliver products or services you’ve
            requested.
          </li>
          <li style={listItemStyle}>
            <strong>Communications:</strong> To contact you with updates,
            marketing materials, promotional offers, or other information that
            may be of interest to you.
          </li>
          <li style={listItemStyle}>
            <strong>Marketplace Improvements:</strong> To better understand how
            Users interact with the Marketplace and improve our products,
            services, and user experience.
          </li>
          <li style={listItemStyle}>
            <strong>Legal Compliance:</strong> To comply with applicable laws and
            regulations, resolve disputes, and enforce agreements.
          </li>
        </ul>
      </div>

      {/* Section 4: Cookies and Tracking Technologies */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>4. Cookies and Tracking Technologies</h3>
        <p style={paragraphStyle}>
          We use cookies and similar tracking technologies to monitor activity on
          our Marketplace and store certain information. Cookies help us provide
          you with a tailored experience and analyze how the Marketplace is used.
          You can instruct your browser to refuse all cookies or to notify you
          when a cookie is being sent. However, if you do not accept cookies, you
          may not be able to use some portions of the Marketplace.
        </p>
      </div>

      {/* Section 5: Data Sharing and Disclosure */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>5. Data Sharing and Disclosure</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Service Providers:</strong> We may share your personal
            information with third-party service providers who assist us in
            operating the Marketplace, conducting our business, or servicing you.
          </li>
          <li style={listItemStyle}>
            <strong>Legal Requirements:</strong> We may disclose personal
            information if required to do so by law or in response to valid
            requests by public authorities.
          </li>
          <li style={listItemStyle}>
            <strong>Business Transfers:</strong> If we are involved in a merger,
            acquisition, or asset sale, your personal information may be
            transferred as part of that transaction.
          </li>
        </ul>
      </div>

      {/* Section 6: Data Security */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>6. Data Security</h3>
        <p style={paragraphStyle}>
          We take reasonable measures to protect your personal information. While
          we strive to use commercially acceptable means to protect your
          personal data, no method of transmission over the internet or method of
          electronic storage is 100% secure. We cannot guarantee absolute
          security, but we do our best to use industry-standard protection
          measures.
        </p>
      </div>

      {/* Section 7: Data Retention */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>7. Data Retention</h3>
        <p style={paragraphStyle}>
          We retain your personal information only for as long as is necessary
          for the purposes set out in this Privacy Policy, to the extent
          necessary to comply with our legal obligations, resolve disputes, and
          enforce our agreements.
        </p>
      </div>

      {/* Section 8: Children's Privacy */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>8. Children’s Privacy</h3>
        <p style={paragraphStyle}>
          Our Marketplace is not intended for individuals under the age of 18. We
          do not knowingly collect personal information from anyone under 18.
          If you are a parent or guardian and become aware that your child has
          provided us with personal information, please contact us so we can
          remove that information.
        </p>
      </div>

      {/* Section 9: Your Rights */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>9. Your Rights</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Access and Update:</strong> You have the right to request
            access to or update your personal data at any time.
          </li>
          <li style={listItemStyle}>
            <strong>Deletion:</strong> You may request that we delete your
            personal data, subject to certain legal exceptions.
          </li>
          <li style={listItemStyle}>
            <strong>Opt-Out:</strong> You can opt-out of receiving promotional
            emails by following the unsubscribe instructions included in those
            emails or by contacting us directly.
          </li>
        </ul>
      </div>

      {/* Section 10: International Data Transfers */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>10. International Data Transfers</h3>
        <p style={paragraphStyle}>
          Your information may be transferred to — and maintained on — computers
          located outside of your state, province, country, or other
          governmental jurisdiction where data protection laws may differ from
          those in your jurisdiction. Your consent to this Privacy Policy,
          followed by your submission of personal data, represents your agreement
          to that transfer.
        </p>
      </div>

      {/* Section 11: Changes to This Privacy Policy */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>11. Changes to This Privacy Policy</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Right to Modify:</strong> We reserve the right to modify or
            update this Privacy Policy at any time.
          </li>
          <li style={listItemStyle}>
            <strong>Notice of Changes:</strong> We will notify you of any
            significant changes via email or through the Marketplace.
          </li>
          <li style={listItemStyle}>
            <strong>Acceptance of Changes:</strong> Continued use of the
            Marketplace after such changes have been posted constitutes your
            acceptance of the new Privacy Policy.
          </li>
        </ul>
      </div>

      {/* Section 12: Contact Information */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>12. Contact Information</h3>
        <p style={paragraphStyle}>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </p>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Email:</strong> hello@caycollective.com
          </li>
          <li style={listItemStyle}>
            <strong>Address:</strong> Sveavägen, Stockholm
          </li>
        </ul>
      </div>

      {/* Acknowledgment */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>Acknowledgment</h3>
        <p style={paragraphStyle}>
          By using the Marketplace, you acknowledge that you have read,
          understood, and agree to the collection and use of your information in
          accordance with this Privacy Policy.
        </p>
      </div>
    </div>
  );
};