// src/redux/productsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, db, storage } from '../../config/firebase';
import { collection, getDocs, onSnapshot, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadString } from 'firebase/storage'; // Firebase Storage utilities

// Thunk to fetch products
export const fetchProducts = createAsyncThunk('products/fetchProducts', async (_, { getState }) => {
    const { status } = getState().products;
    if (status === 'succeeded') {
        return; // Prevent re-fetching if already succeeded
    }

    const user = auth.currentUser;
    if (user) {
        console.log('Fetching products for user:', user.uid);
        const productsCollectionRef = collection(db, 'users', user.uid, 'products');
        const productsSnapshot = await getDocs(productsCollectionRef);

        const products = productsSnapshot.docs.map(doc => {
            const productData = doc.data();
            
            // Check if the product has a default variant
            const isDefault = productData.options?.length === 0 || productData.options?.length === 1 
                && productData.options[0]?.name === "Title" 
                && productData.options[0]?.values?.[0] === "Default Title";

            console.log('Fetched product:', productData);

            return {
                id: doc.id,
                title: productData.title || "Untitled",
                body_html: productData.body_html || "No description available",
                created_at: productData.created_at || "N/A",
                handle: productData.handle || "N/A",
                image: productData.image || {},
                images: productData.images || [],
                status: productData.status || "N/A",
                variant_ids: productData.variant_ids || [],
                product_type: productData.product_type || "N/A",
                vendor: productData.vendor || "N/A",
                tags: productData.tags || [],
                description: productData.body_html || "No description available",
                options: isDefault ? [] : productData.options || [], // Set options to an empty list if isDefault is true
                variants: productData.variants || [],
                generatedVariants: [],
                taxable: productData.variants[0].taxable,
                isDefault // Add the isDefault flag
            };
        });
        console.log('Returning products:', products);
        return products;
    } else {
        console.log('No user found');
        return [];
    }
});

// Function to upload base64 image to Firebase Storage and return the URL
const uploadImageToStorage = async (base64Image, fileName) => {
    const storageRef = ref(storage, `productImages/${fileName}`);
    // Upload the base64 image
    await uploadString(storageRef, base64Image, 'data_url');
    // Get the download URL once uploaded
    return await getDownloadURL(storageRef);
};

export const updateProductInFirestore = createAsyncThunk(
    'products/logProductEdit',
    async ({ id }, { getState }) => {
        const user = auth.currentUser;
        if (user) {
            const state = getState();
            const product = state.products.products.find(product => product.id === id);

            // Fetch user's locationId and creatorName from Firestore
            const userDocRef = doc(db, 'users', user.uid);
            const userSnapshot = await getDoc(userDocRef);
            if (!userSnapshot.exists()) {
                throw new Error('User not found');
            }

            const { locationId, creatorName } = userSnapshot.data();

            // Prepare image upload promises with folder based on creatorName
            const imageUploadPromises = product.images.map(async (image) => {
                if (image.base64) {
                    // Upload new image to a folder named after the creator
                    const folderPath = `productImages/${creatorName}/`;
                    return await uploadImageToStorage(image.base64, `${folderPath}${image.fileName}`);
                } else if (image.src) {
                    // Existing Shopify image, return the URL
                    return image.src;
                }
                throw new Error('Image does not have base64 data or a URL');
            });

            // Wait for all images to be uploaded and get their URLs
            const imageUrls = await Promise.all(imageUploadPromises);

            // Reference to the specific product's 'editProductHistory' sub-collection
            const editHistoryRef = collection(db, 'users', user.uid, 'products', id, 'editProductHistory');

            // Prepare the log entry with product data and user details
            const editLog = {
                productId: id,
                updatedProduct: {
                    title: product.title,
                    body_html: product.description,
                    images: imageUrls, // Use the URLs instead of base64
                    optionsToPost: product.isDefault ? [] : product.options,
                    variants: product.variants,
                    generatedVariants: product.generatedVariants,
                    isDefault: product.isDefault,
                },
                editedAt: new Date(),
                userId: user.uid,
                locationId,  // Include locationId
                creatorName, // Include creatorName
            };

            // Add the log entry to Firestore inside the product's edit history
            await addDoc(editHistoryRef, editLog);

            // Update user's locationId and creatorName in Firestore (if needed)
            await updateDoc(userDocRef, { locationId, creatorName });

            return { id, updatedProduct: editLog.updatedProduct }; // Return the updated product to update the state
        } else {
            throw new Error('No user logged in');
        }
    }
);


// Function to set up a Firestore listener for products
export const setupProductsListener = () => (dispatch) => {
    const user = auth.currentUser;
    if (user) {
        const productsCollectionRef = collection(db, 'users', user.uid, 'products');

        // Listen for real-time updates to the products collection
        const unsubscribe = onSnapshot(productsCollectionRef, (snapshot) => {
            const products = snapshot.docs.map(doc => {
                const productData = doc.data();

                // Check if the product has a default variant
                const isDefault = productData.options?.length === 0 || productData.options?.length === 1 
                && productData.options[0]?.name === "Title" 
                && productData.options[0]?.values?.[0] === "Default Title";

                return {
                    id: doc.id,
                    title: productData.title || "Untitled",
                    body_html: productData.body_html || "No description available",
                    created_at: productData.created_at || "N/A",
                    handle: productData.handle || "N/A",
                    image: productData.image || {},
                    images: productData.images || [],
                    status: productData.status || "N/A",
                    price: productData.variants?.[0]?.price || "N/A",
                    variant_ids: productData.variant_ids || [],
                    product_type: productData.product_type || "N/A",
                    vendor: productData.vendor || "N/A",
                    tags: productData.tags || [],
                    description: productData.body_html || "No description available",
                    options: isDefault ? [] : productData.options || [], // Set options to an empty list if isDefault is true
                    variants: productData.variants || [],
                    generatedVariants: [],
                    taxable: productData.variants[0].taxable,
                    isDefault // Add the isDefault flag
                };
            });

            dispatch(fetchProducts.fulfilled(products)); // Dispatch the fetched data
        });

        return unsubscribe; // Return the unsubscribe function for cleanup if needed
    }
};

// Function to check and update the `isDefault` flag based on options
const updateIsDefaultFlag = (options) => {
    return options?.length === 0 || options?.length === 1 
    && options[0]?.name === "Title" 
    && options[0]?.values?.[0] === "Default Title";
};

const initialState = {
    products: [],
    status: 'idle', // idle | loading | succeeded | failed
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        // Action to locally update product data in the store
        updateProduct: (state, action) => {
            const { id, field, value } = action.payload;
            const product = state.products.find((product) => product.id === id);
            if (product) {
                product[field] = value; // Update the specific field (name, price, description, images, options)

                // If the options are updated, recalculate the isDefault flag
                if (field === 'options') {
                    product.isDefault = updateIsDefaultFlag(value);

                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                if (state.status === 'succeeded') return;
                state.status = 'loading';
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload; // Set the products data
            })
            .addCase(fetchProducts.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateProductInFirestore.fulfilled, (state, action) => {
                const { id, updatedProduct } = action.payload;
                const productIndex = state.products.findIndex((product) => product.id === id);
                if (productIndex !== -1) {
                    state.products[productIndex] = { ...state.products[productIndex], ...updatedProduct };
                }
            });
    },
});

export const { updateProduct } = productsSlice.actions;
export default productsSlice.reducer;