import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import confetti from 'canvas-confetti';
import { addDoc, collection, db, auth } from "../config/firebase";
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
  name: yup.string().required("Creator name is required"),
  legalName: yup.string().required("Legal name for shipping is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  terms: yup.bool()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('Terms must be accepted'),
});

export const ApplyFirst = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('+46'); // Default country code

  const navigate = useNavigate();

  // Pull setValue from useForm so we can programmatically set phoneNumber
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    setIsLoading(true);

    const newEntry = {
      name: data.name,
      legalName: data.legalName,
      phoneNumber: data.phoneNumber, // now this is populated correctly
      timestamp: new Date(),
      googleName: auth.currentUser?.displayName || '',
      googleEmail: auth.currentUser?.email || '',
      googleUserId: auth.currentUser?.uid || '',
    };

    try {
      const userId = auth.currentUser.uid;
      await addDoc(collection(db, "users", userId, "applications"), newEntry);

      confetti({
        particleCount: 300,
        spread: 100,
        origin: { y: 0.6 },
      });

      reset();
      navigate('/account-status');
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="container p-4" style={{ minHeight: '100vh' }}>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column align-items-center">

        {/* Creator Name */}
        <div className="card shadow-sm mb-4" style={{ maxWidth: '500px', width: '100%' }}>
          <div className="card-body">
            <h2 className="text-center mb-4">Creator Name</h2>
            <p className="text-muted text-center mb-4">
              Please provide the exact name as it appears on <strong>CayCollective</strong>.
            </p>
            <div className="form-group mb-4">
              <input
                {...register("name")}
                type="text"
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                placeholder="Enter your creator name"
              />
              <div className={`invalid-feedback ${errors.name ? 'd-block' : ''}`}>
                {errors.name?.message}
              </div>
            </div>
          </div>
        </div>

        {/* Legal Name */}
        <div className="card shadow-sm mb-4" style={{ maxWidth: '500px', width: '100%' }}>
          <div className="card-body">
            <h2 className="text-center mb-4">Legal Name</h2>
            <p className="text-muted text-center mb-4">
              Please provide your full legal name for shipping purposes.
            </p>
            <div className="form-group mb-4">
              <input
                {...register("legalName")}
                type="text"
                className={`form-control ${errors.legalName ? 'is-invalid' : ''}`}
                placeholder="Enter your legal name"
              />
              <div className={`invalid-feedback ${errors.legalName ? 'd-block' : ''}`}>
                {errors.legalName?.message}
              </div>
            </div>
          </div>
        </div>

        {/* Phone Number */}
        <div className="card shadow-sm mb-4" style={{ maxWidth: '500px', width: '100%' }}>
          <div className="card-body">
            <h2 className="text-center mb-4">Phone Number</h2>
            <p className="text-muted text-center mb-4">
              Please provide a valid phone number, including the country code.
            </p>
            <div className="form-group mb-4">
              <PhoneInput
                country="se" // Default to Sweden
                value={phoneNumber}
                onChange={(value) => {
                  setPhoneNumber(value);
                  setValue("phoneNumber", value); // Sync to the form
                }}
                inputClass={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                containerStyle={{ width: '100%' }}
                inputStyle={{ width: '100%' }}
              />
              <div className={`invalid-feedback ${errors.phoneNumber ? 'd-block' : ''}`}>
                {errors.phoneNumber?.message}
              </div>
            </div>
          </div>
        </div>

        {/* Terms Checkbox */}
        <div className="form-check mb-4">
          <input
            type="checkbox"
            {...register("terms")}
            id="termsCheckbox"
            className="form-check-input"
          />
          <label htmlFor="termsCheckbox" className="form-check-label">
            I agree to the{' '}
            <a
              href="/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Terms of Service
            </a>
          </label>
          <div className={`text-danger ${errors.terms ? 'd-block' : ''}`}>
            {errors.terms?.message}
          </div>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="btn btn-primary btn-lg"
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};