// src/redux/ordersSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, db } from '../../config/firebase';
import { collection, getDocs, onSnapshot } from "firebase/firestore";

// Thunk to fetch orders
export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (_, { getState }) => {
    const { status } = getState().orders;
    if (status === 'succeeded') return; // Prevent re-fetching if already succeeded

    const user = auth.currentUser;
    if (user) {
        const ordersCollectionRef = collection(db, 'users', user.uid, 'orders');
        const ordersSnapshot = await getDocs(ordersCollectionRef);

        const orders = ordersSnapshot.docs.map(doc => {
            const data = doc.data();
            const fulfillmentOrder = data.fulfillmentOrder || {};

            // Extract assigned_location and destination
            const assigned_location = fulfillmentOrder.assigned_location || {};
            const destination = fulfillmentOrder.destination || {};

            // Build line items
            const line_items = (fulfillmentOrder.line_items || []).map(item => ({
                name: item.name || "N/A",
                price: item.price || "0.00",
                quantity: item.quantity || 1,
                vendor: item.vendor || "N/A",
            }));

            // Calculate total if `data.total` is missing or zero
            let computedTotal = 0;
            if (!data.total) {
                line_items.forEach(li => {
                    const priceNum = parseFloat(li.price) || 0;
                    computedTotal += (priceNum * li.quantity);
                });
            }
            // Use data.total if it exists, otherwise use the computed sum
            const finalTotal = data.total
                ? data.total
                : computedTotal.toFixed(2);

            return {
                id: doc.id,
                // Basic fields
                created_at: fulfillmentOrder.created_at || "N/A",
                fulfill_at: fulfillmentOrder.fulfill_at || "N/A",
                fulfilled: data.fulfilled || false,

                // Tracking fields
                trackingNumber: data.trackingNumber || "N/A",
                trackingLink: data.trackingLink || "N/A",
                QRcodePostNord: data.QRcodePostNord || "N/A",
                ShippingLabelPostNord: data.ShippingLabelPostNord || "N/A",

                // Basic customer fields for quick reference
                customer: destination.first_name
                    ? `${destination.first_name} ${destination.last_name || ''}`.trim()
                    : "N/A",
                customerPhone: destination.phone || "",
                customerEmail: destination.email || "",

                // Full "destination" (buyer) details
                destination: {
                    first_name: destination.first_name || "",
                    last_name: destination.last_name || "",
                    address1: destination.address1 || "",
                    address2: destination.address2 || "",
                    city: destination.city || "",
                    province: destination.province || "",
                    zip: destination.zip || "",
                    country: destination.country || "",
                    phone: destination.phone || "",
                    email: destination.email || "",
                },

                // "assigned_location" (seller) details
                assigned_location: {
                    name: assigned_location.name || "N/A",
                    address1: assigned_location.address1 || "",
                    address2: assigned_location.address2 || "",
                    city: assigned_location.city || "",
                    province: assigned_location.province || "",
                    zip: assigned_location.zip || "",
                    country: assigned_location.country_code || "",
                    phone: assigned_location.phone || "",
                },

                // Line items array
                line_items,

                // If data.total is missing, use the computed sum
                total: finalTotal,
            };
        });
        return orders;
    }
    return [];
});

// Function to set up a Firestore listener for orders
export const setupOrdersListener = () => (dispatch) => {
    const user = auth.currentUser;
    if (user) {
        const ordersCollectionRef = collection(db, 'users', user.uid, 'orders');

        // Listen for real-time updates
        const unsubscribe = onSnapshot(ordersCollectionRef, (snapshot) => {
            const orders = snapshot.docs.map(doc => {
                const data = doc.data();
                const fulfillmentOrder = data.fulfillmentOrder || {};

                const assigned_location = fulfillmentOrder.assigned_location || {};
                const destination = fulfillmentOrder.destination || {};

                // Build line items
                const line_items = (fulfillmentOrder.line_items || []).map(item => ({
                    name: item.name || "N/A",
                    price: item.price || "0.00",
                    quantity: item.quantity || 1,
                    vendor: item.vendor || "N/A",
                }));

                // Calculate total if `data.total` is missing or zero
                let computedTotal = 0;
                if (!data.total) {
                    line_items.forEach(li => {
                        const priceNum = parseFloat(li.price) || 0;
                        computedTotal += (priceNum * li.quantity);
                    });
                }
                const finalTotal = data.total
                    ? data.total
                    : computedTotal.toFixed(2);

                return {
                    id: doc.id,
                    created_at: fulfillmentOrder.created_at || "N/A",
                    fulfill_at: fulfillmentOrder.fulfill_at || "N/A",
                    fulfilled: data.fulfilled || false,

                    trackingNumber: data.trackingNumber || "N/A",
                    trackingLink: data.trackingLink || "N/A",
                    QRcodePostNord: data.QRcodePostNord || "N/A",
                    ShippingLabelPostNord: data.ShippingLabelPostNord || "N/A",

                    customer: destination.first_name
                        ? `${destination.first_name} ${destination.last_name || ''}`.trim()
                        : "N/A",
                    customerPhone: destination.phone || "",
                    customerEmail: destination.email || "",

                    destination: {
                        first_name: destination.first_name || "",
                        last_name: destination.last_name || "",
                        address1: destination.address1 || "",
                        address2: destination.address2 || "",
                        city: destination.city || "",
                        province: destination.province || "",
                        zip: destination.zip || "",
                        country: destination.country || "",
                        phone: destination.phone || "",
                        email: destination.email || "",
                    },

                    assigned_location: {
                        name: assigned_location.name || "N/A",
                        address1: assigned_location.address1 || "",
                        address2: assigned_location.address2 || "",
                        city: assigned_location.city || "",
                        province: assigned_location.province || "",
                        zip: assigned_location.zip || "",
                        country: assigned_location.country_code || "",
                        phone: assigned_location.phone || "",
                    },

                    line_items,
                    total: finalTotal,
                };
            });

            dispatch(fetchOrders.fulfilled(orders));
        });

        return unsubscribe;
    }
};

const initialState = {
    orders: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                if (state.status === 'succeeded') return;
                state.status = 'loading';
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.orders = action.payload;
            })
            .addCase(fetchOrders.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default ordersSlice.reducer;